<template>
  <div
    class="fill-height"
  >
    <o-map ref="map" />

    <v-container
      fluid
      class="map-tools fill-height"
    >
      <v-row
        no-gutters
        class="fill-height"
      >
        <v-col
          cols="2"
          class="d-flex fill-height flex-column align-start"
        >
          <div
            v-if="$parameter('TRAFFIC_DATA_ENABLE')"
            v-show="displayItems.charts.display"
            class="fill-height"
          >
            <div
              class="d-flex flex-column justify-space-around fill-height"
            >
              <o-card-module
                :has-data="jamFactorChartSections && !!Object.keys(jamFactorChartSections).length"
                :loading="sectionsLoading"
                width="200"
                height="200"
                class="pa-2 my-2"
              >
                <jam-factor-chart
                  v-if="jamFactorChartSections"
                  :sections="jamFactorChartSections"
                />
              </o-card-module>

              <o-card-module
                :has-data="speedChartData !== null && !!speedChartData.length && speedChartData.some(value => value !== null)"
                :loading="timeline.loading"
                width="200"
                height="200"
                class="pa-0 my-2"
              >
                <div class="d-flex flex-column fill-height">
                  <div
                    class="d-flex pa-2"
                    :style="`color: ${getSpeedColor(cityAverageSpeed, 50)}`"
                  >
                    <div
                      v-if="cityAverageSpeed !== null"
                      class="text-h6"
                    >
                      {{ cityAverageSpeed }} km/h
                    </div>

                    <v-spacer />

                    <div
                      v-if="cityAverageSpeed !== null"
                      class="text-right"
                    >
                      <v-icon
                        v-if="cityAverageSpeedTrend === -1"
                        :color="getSpeedColor(cityAverageSpeed, 50)"
                      >
                        mdi-arrow-bottom-right
                      </v-icon>
                      <v-icon
                        v-else-if="cityAverageSpeedTrend === 0"
                        :color="getSpeedColor(cityAverageSpeed, 50)"
                      >
                        mdi-approximately-equal
                      </v-icon>
                      <v-icon
                        v-else
                        :color="getSpeedColor(cityAverageSpeed, 50)"
                      >
                        mdi-arrow-top-right
                      </v-icon>
                    </div>
                  </div>

                  <v-spacer />

                  <speed-chart
                    v-if="speedChartData"
                    :history="speedChartData"
                    :height="110"
                    :offset="speedChartOffset"
                    :max-speed="speedChartMaxSpeed"
                  />
                </div>
              </o-card-module>
            </div>
          </div>
        </v-col>

        <v-col
          cols="8"
          class="d-flex flex-column justify-end"
        >
          <o-card-module
            v-if="$parameter('TRAFFIC_CONTROL_ENABLE') && crossroads && crossroads.length"
            v-show="displayItems.stations.display"
            :loading="crossroadsLoading"
            class="mb-n3"
            :disable-hover="displayItems.stations.resizing"
          >
            <stations
              :loading="crossroadsLoading"
              :crossroads="crossroads"
              @crossroad-locate="flyToCrossroad"
              @station-watcher-mode-click="(crossroadId) => selectedItems.crossroadId = crossroadId"
              @crossroad-click="(crossroadId) => selectCrossroad(crossroadId)"
              @crossroad-show="showCrossroad"
              @crossroad-hide="hideCrossroad"
              @crossroad-show-all="showAllCrossroads"
              @crossroad-hide-all="hideAllCrossroads"
              @resize="(resizing) => displayItems.stations.resizing = resizing"
            />
          </o-card-module>
        </v-col>

        <v-col
          cols="2"
          class="d-flex fill-height flex-column align-end"
        >
          <div
            style="width: 140px;"
          >
            <display-selector
              v-model="displayItems"
            />
          </div>

          <v-spacer />

          <div
            v-if="$parameter('TRAFFIC_DATA_ENABLE')"
            v-show="displayItems.timeline.display"
            class="flex-grow-1 my-2"
          >
            <timeline-slider
              class="fill-height"
              :step="timeline.step"
              :disabled="timeline.loading"
              @change="(date) => timeline.date = date"
              @period-change="onTimelinePeriodChange"
            />
          </div>

          <v-spacer />
        </v-col>
      </v-row>
    </v-container>

    <section-modal
      v-if="selectedItems.sectionId"
      :section-id="selectedItems.sectionId"
      :color="selectedColor"
      :text-color="selectedTextColor"
      :date="timeline.date"
      @close="unselectSection"
    />

    <crossroad-modal
      v-if="selectedItems.crossroadId"
      :station-id="selectedItems.stationId"
      :crossroad-id="selectedItems.crossroadId"
      @close="selectedItems.crossroadId = null"
    />
  </div>
</template>

<script>
  import WebSocketTopic from '@api/web-socket-topic';
  import { getJamFactorType, getJamFactorColor, getJamFactorTextColor, getSpeedColor, JAM_FACTOR_TYPE_NO_DATA } from '@utils/section';
  import cloneDeep from 'lodash/cloneDeep';
  import webSocketClient from '@mixin/web-socket-client';
  import { mapState } from 'vuex';
  const moment = require('moment-timezone');

  export default {
    components: {
      SectionModal: () => import('@/views/admin/components/dashboard/sectionModal/SectionModal'),
      CrossroadModal: () => import('@/views/admin/components/dashboard/crossroadModal/CrossroadModal'),
      JamFactorChart: () => import('@/views/admin/components/dashboard/JamFactorChart'),
      SpeedChart: () => import('@/views/admin/components/dashboard/SpeedChart'),
      TimelineSlider: () => import('@/views/admin/components/dashboard/TimelineSlider'),
      DisplaySelector: () => import('@/views/admin/components/dashboard/DisplaySelector'),
      Stations: () => import('@/views/admin/components/dashboard/Stations'),
    },

    mixins: [webSocketClient],

    apollo: {
      crossroads: {
        query: require('@gql/views/admin/dashboard/getCrossroads.gql'),
        client: 'stations',
        fetchPolicy: 'no-cache',
        result (res, key) {
          this.crossroadsLoading = res.loading;
        },
        skip () {
          return !this.$parameter('TRAFFIC_CONTROL_ENABLE');
        },
        update (data) {
          this.map.crossroads = cloneDeep(data.getCrossroads);

          return data.getCrossroads;
        },
        error: function () {
          this.$flash('errors.apollo.query.crossroads', 'error');
        },
      },
      sections: {
        query: require('@gql/views/admin/dashboard/getSections.gql'),
        client: 'floating-traffic-data',
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        result (res, key) {
          this.sectionsLoading = res.loading;
        },
        skip () {
          return !this.$parameter('TRAFFIC_DATA_ENABLE');
        },
        update (data) {
          const sections = {};
          for (const section of data.getSections) {
            sections[section.id] = section;
          }

          this.map.sections = cloneDeep(data.getSections);

          return sections;
        },
        error: function () {
          this.$flash('errors.apollo.query.sections', 'error');
        },
      },
      history: {
        query: require('@gql/views/admin/dashboard/history.gql'),
        variables: function () {
          return {
            timezone: moment.tz.guess(),
            from: moment(this.timeline.from).format('Y-MM-DD HH:mm:ss'),
            jamFactorTo: moment(this.timeline.to).format('Y-MM-DD HH:mm:ss'),
            cityAverageSpeedTo: this.getCityAverageSpeedTo().format('Y-MM-DD HH:mm:ss'),
            step: this.timeline.step,
            maxValue: this.speedChartMaxSpeed,
          };
        },
        notifyOnNetworkStatusChange: true,
        result (res, key) {
          if (!res.loading) {
            this.timeline.loading = false;
          }
        },
        skip: function () {
          return !this.$parameter('TRAFFIC_DATA_ENABLE') || this.timeline.from === null || this.timeline.to === null;
        },
        client: 'stats',
        fetchPolicy: 'no-cache',
        update: function (data) {
          for (const type of Object.keys(data)) {
            const values = data[type];
            this.timeline.history[type] = {};
            for (const item of values) {
              if (this.timeline.history[type][item.entityId] === undefined) {
                this.timeline.history[type][item.entityId] = {};
              }
              this.timeline.history[type][item.entityId][parseInt(item.timestamp)] = item.value;
            }
          }

          this.timeline.loading = false;
          const cityAverageSpeed = data.cityAverageSpeed;
          const minute = moment().minute() >= 30 ? 30 : 0;
          const date = moment().subtract(1, 'day').set({
            minute: minute,
            second: 0,
            millisecond: 0,
          });
          const step = 30;
          this.cityAverageSpeedHistory = {};
          for (let i = 0; i < (24 * 60 / step); i++) {
            this.cityAverageSpeedHistory[date.unix()] = null;
            date.add(step, 'minutes');
          }

          for (const speed of cityAverageSpeed) {
            this.cityAverageSpeedHistory[speed.timestamp] = speed.value;
          }

          this.speedChartData = Object.values(this.cityAverageSpeedHistory);
        },
        error: function () {
          this.$flash('errors.apollo.query.history', 'error');
        },
      },
    },

    data: function () {
      return {
        timeline: {
          date: null,
          from: null,
          to: null,
          step: 30,
          history: [],
          loading: true,
        },
        selectedItems: {
          sectionId: null,
          crossroadId: null,
          stationId: null,
        },
        sectionsLoading: true,
        crossroadsLoading: true,
        cityAverageSpeedHistory: null,
        speedChartData: null,
        speedChartMaxSpeed: 50,
        map: {
          sections: [],
          crossroads: [],
        },
        displayItems: this.getDisplayItems(),
      };
    },

    computed: {
      ...mapState('auth', ['user']),

      selectedColor () {
        if (this.selectedJamFactorType !== null) {
          return getJamFactorColor(this.selectedJamFactorType);
        } else {
          return null;
        }
      },

      selectedTextColor () {
        if (this.selectedJamFactorType !== null) {
          return getJamFactorTextColor(this.selectedJamFactorType);
        } else {
          return null;
        }
      },

      selectedJamFactorType () {
        if (this.selectedItems.sectionId !== null) {
          const sectionId = this.selectedItems.sectionId;
          let jamFactor;

          if (this.timeline.date !== null) {
            const timestamp = moment(this.timeline.date).utc().unix();
            if (this.timeline.history.jamFactor[sectionId] !== undefined && this.timeline.history.jamFactor[sectionId][timestamp] !== undefined) {
              jamFactor = this.timeline.history.jamFactor[sectionId][timestamp];
            } else {
              return JAM_FACTOR_TYPE_NO_DATA;
            }
          } else {
            const section = this.sections[sectionId];
            jamFactor = section.jamFactor;
          }

          return getJamFactorType(jamFactor);
        } else {
          return JAM_FACTOR_TYPE_NO_DATA;
        }
      },

      cityAverageSpeed () {
        if (!this.timeline.loading && this.timeline.date !== null) {
          const currentTimestamp = moment(this.timeline.date).unix();

          if (this.cityAverageSpeedHistory[currentTimestamp] !== undefined && this.cityAverageSpeedHistory[currentTimestamp] !== null) {
            return this.cityAverageSpeedHistory[currentTimestamp].toFixed(2);
          }
        } else if (this.sections) {
          let average = 0;
          let total = 0;
          for (const id of Object.keys(this.sections)) {
            const section = this.sections[id];
            if (section.averageSpeed !== null && section.averageSpeed <= this.speedChartMaxSpeed) {
              average += section.averageSpeed * section.length;
              total += section.length;
            }
          }

          if (total > 0) {
            return (average / total).toFixed(2);
          }
        }

        return null;
      },

      cityAverageSpeedTrend () {
        if (this.cityAverageSpeedHistory === null) {
          return 0;
        }

        let trend = 0;
        const timestamps = Object.keys(this.cityAverageSpeedHistory);

        if (!this.timeline.loading && this.timeline.date !== null) {
          const currentTimestamp = moment(this.timeline.date).unix();
          const index = timestamps.indexOf(currentTimestamp.toString());

          if (index > 0) {
            const previousTimestamp = timestamps[index - 1];
            trend = this.cityAverageSpeedHistory[previousTimestamp] - this.cityAverageSpeedHistory[currentTimestamp];
          } else {
            return 0;
          }
        } else {
          const lastTimestamp = timestamps[timestamps.length - 1];
          trend = this.cityAverageSpeedHistory[lastTimestamp] - this.cityAverageSpeed;
        }

        const threshold = 0.1;
        if (trend <= -threshold) {
          return 1;
        } else if (trend <= threshold) {
          return 0;
        } else {
          return -1;
        }
      },

      jamFactorChartSections () {
        if (!this.sections) {
          return null;
        }

        const sections = [];

        if (!this.timeline.loading && this.timeline.date !== null) {
          const currentTimestamp = moment(this.timeline.date).unix();

          for (const id of Object.keys(this.sections)) {
            const section = this.sections[id];

            if (this.timeline.history.jamFactor[id] !== undefined) {
              if (this.timeline.history.jamFactor[id][currentTimestamp] !== undefined && this.timeline.history.jamFactor[id][currentTimestamp] !== null) {
                sections.push({
                  length: section.length,
                  jamFactor: this.timeline.history.jamFactor[id][currentTimestamp],
                });
              }
            }
          }
        } else {
          for (const id of Object.keys(this.sections)) {
            const section = this.sections[id];

            if (section.jamFactor !== null) {
              sections.push({
                length: section.length,
                jamFactor: section.jamFactor,
              });
            }
          }
        }

        return sections;
      },

      speedChartOffset () {
        if (!this.timeline.loading && this.timeline.date !== null) {
          const currentTimestamp = moment(this.timeline.date).unix();
          const timestamps = Object.keys(this.cityAverageSpeedHistory);

          return timestamps.indexOf(currentTimestamp.toString());
        }

        return null;
      },
    },

    watch: {
      'map.sections': function () {
        this.refreshSectionFeatures();
      },
      'map.crossroads': function () {
        this.refreshCrossroadFeatures();
      },
      'displayItems.traffic.display': function () {
        this.refreshSectionFeatures();
      },
      'displayItems.stations.display': function () {
        this.refreshCrossroadFeatures();
      },
      'timeline.date': async function (newValue, oldValue) {
        if (!this.timeline.loading && this.timeline.date !== null) {
          // Replace values by history values
          const currentTimestamp = moment(this.timeline.date).unix();
          for (const section of this.map.sections) {
            const sectionId = section.id;
            if (this.timeline.history.jamFactor[sectionId] !== undefined && this.timeline.history.jamFactor[sectionId][currentTimestamp] !== undefined) {
              section.jamFactor = this.timeline.history.jamFactor[sectionId][currentTimestamp];
            } else {
              section.jamFactor = -1;
            }
          }
        } else {
          for (const section of this.map.sections) {
            const sectionId = section.id;
            section.jamFactor = this.sections[sectionId].jamFactor;
          }
        }

        this.refreshSectionFeatures();
      },
    },

    mounted () {
      this.$store.commit('map/reset');

      if (this.$parameter('TRAFFIC_DATA_ENABLE')) {
        this.webSocketSubscribe(WebSocketTopic.SECTIONS_CREATED, this.onSectionsCreated);
        this.webSocketSubscribe(WebSocketTopic.SECTIONS_UPDATED, this.onSectionsUpdated);
      }

      if (this.$parameter('TRAFFIC_CONTROL_ENABLE')) {
        this.webSocketSubscribe(WebSocketTopic.CROSSROAD_IN_CONTROL, this.onCrossroadInControl);
        this.webSocketSubscribe(WebSocketTopic.CROSSROAD_OUT_OF_CONTROL, this.onCrossroadOutOfControl);
        this.webSocketSubscribe(WebSocketTopic.CROSSROAD_USER_STATE_CHANGED, this.onCrossroadUserStateChanged);
        this.webSocketSubscribe(WebSocketTopic.CROSSROAD_STATION_WATCHER_STATE_CHANGED, this.onCrossroadStationWatcherStateChanged);
        this.webSocketSubscribe(WebSocketTopic.CROSSROAD_STATION_STATE_CHANGED, this.onCrossroadStationStateChanged);
        this.webSocketSubscribe(WebSocketTopic.STATION_SYNCHRONIZED, this.onStationSynchronized);
        this.webSocketSubscribe(WebSocketTopic.STATION_UNSYNCHRONIZED, this.onStationUnsynchronized);
        this.webSocketSubscribe(WebSocketTopic.STATION_CONNECTED, this.onStationConnected);
        this.webSocketSubscribe(WebSocketTopic.STATION_DISCONNECTED, this.onStationDisconnected);
      }
    },

    created () {
      this.$bus.$on('map.feature.click', this.onFeatureClick);
    },

    destroyed () {
      this.$bus.$off('map.feature.click', this.onFeatureClick);
    },

    methods: {
      getDisplayItems () {
        const displayItems = {};

        if (this.$parameter('TRAFFIC_DATA_ENABLE')) {
          displayItems.traffic = {
            icon: 'mdi-car-multiple',
            display: true,
          };

          displayItems.charts = {
            icon: 'mdi-chart-arc',
            display: true,
          };

          displayItems.timeline = {
            icon: 'mdi-timeline-clock',
            display: true,
          };
        }

        if (this.$parameter('TRAFFIC_CONTROL_ENABLE')) {
          displayItems.stations = {
            icon: 'mdi-format-list-bulleted',
            display: true,
            resizing: false,
          };
        }

        return displayItems;
      },

      refreshSectionFeatures () {
        if (this.map.sections) {
          if (this.displayItems.traffic.display) {
            this.$store.commit('map/setFeatures', {
              type: 'section',
              features: this.map.sections,
            });
          } else {
            this.$store.commit('map/removeAllFeatures', {
              type: 'section',
            });
          }
        }
      },

      refreshCrossroadFeatures () {
        if (this.map.crossroads) {
          if (this.displayItems.stations.display) {
            this.$store.commit('map/setFeatures', {
              type: 'crossroad',
              features: this.map.crossroads.filter((crossroad) => crossroad.location),
            });
          } else {
            this.$store.commit('map/removeAllFeatures', {
              type: 'crossroad',
            });
          }
        }
      },

      onFeatureClick (type, id) {
        switch (type) {
          case 'section':
            this.selectSection(id);
            break;
          case 'crossroad':
            this.selectCrossroad(id);
            break;
        }
      },

      onSectionsCreated: function (sections) {
        if (this.sections) {
          for (const id of Object.keys(sections)) {
            const section = sections[id];

            if (this.sections[id] === undefined) {
              this.sections[id] = {
                id: section.id,
                jamFactor: section.jamFactor,
                averageSpeed: section.averageSpeed,
                averageSpeedUncut: section.averageSpeedUncut,
                freeFlowSpeed: section.freeFlowSpeed,
                confidence: section.confidence,
                tmcCode: section.tmcCode,
                traversability: section.traversability,
                olr: section.olr,
                length: section.length,
                updatedAt: section.updatedAt,
                shape: section.shape,
                subSegments: section.subSegments,
              };
            }
          }

          if (this.timeline.date === null) {
            this.map.sections = Object.values(this.sections);
          }
        }

        this.refreshHistory();
      },

      onSectionsUpdated: function (sections) {
        if (this.sections) {
          const updatedIds = Object.keys(sections);

          for (const id of updatedIds) {
            const section = sections[id];

            if (this.sections[id] !== undefined) {
              this.sections[id].jamFactor = section.jamFactor;
              this.sections[id].averageSpeed = section.averageSpeed;
              this.sections[id].averageSpeedUncut = section.averageSpeedUncut;
              this.sections[id].freeFlowSpeed = section.freeFlowSpeed;
              this.sections[id].confidence = section.confidence;
              this.sections[id].updatedAt = section.updatedAt;
            }
          }

          if (this.timeline.date === null) {
            this.map.sections = Object.values(this.sections);
          }
        }

        this.refreshHistory();
      },

      onCrossroadStationStateChanged: function (payload) {
        this.$nextTick(this.refreshCrossroads);
      },

      onStationSynchronized: function (payload) {
        this.$nextTick(this.refreshCrossroads);
      },

      onStationUnsynchronized: function (payload) {
        this.$nextTick(this.refreshCrossroads);
      },

      onStationConnected: function (payload) {
        this.$nextTick(this.refreshCrossroads);
      },

      onStationDisconnected: function (payload) {
        this.$nextTick(this.refreshCrossroads);
      },

      onCrossroadInControl: function (payload) {
        this.$nextTick(this.refreshCrossroads);
      },

      onCrossroadOutOfControl: function (payload) {
        this.$nextTick(this.refreshCrossroads);
      },

      onCrossroadStationWatcherStateChanged: function (payload) {
        this.refreshCrossroads();
      },

      onCrossroadUserStateChanged: function (payload) {
        this.$nextTick(this.refreshCrossroads);
      },

      selectSection (id) {
        if (this.selectedItems.sectionId) {
          this.unselectSection();
        }

        this.selectedItems.sectionId = id;
        const section = this.map.sections.find((section) => section.id === this.selectedItems.sectionId);
        section.selected = true;
        if (this.map.sections && this.displayItems.traffic.display) {
          this.$store.commit('map/updateFeature', {
            type: 'section',
            feature: section,
          });
        }
      },

      unselectSection () {
        if (this.selectedItems.sectionId !== null) {
          const section = this.map.sections.find((section) => section.id === this.selectedItems.sectionId);
          section.selected = false;
          this.selectedItems.sectionId = null;
          if (this.map.sections && this.displayItems.traffic.display) {
            this.$store.commit('map/updateFeature', {
              type: 'section',
              feature: section,
            });
          }
        }
      },

      selectCrossroad (crossroadId, stationId) {
        if (this.selectedItems.crossroadId) {
          this.unselectCrossroad();
        }

        const crossroad = this.crossroads.find((crossroad) => crossroad.id === crossroadId);
        this.selectedItems.crossroadId = crossroadId;
        this.selectedItems.stationId = crossroad.station.id;
      },

      unselectCrossroad () {
        if (this.selectedItems.crossroadId !== null) {
          this.selectedItems.crossroadId = null;
          this.selectedItems.stationId = null;
        }
      },

      onTimelinePeriodChange (fromDate, toDate) {
        this.timeline.from = fromDate;
        this.timeline.to = toDate;
        this.refreshHistory();
      },

      getSpeedColor (speed, freeFlowSpeed) {
        return getSpeedColor(speed, freeFlowSpeed);
      },

      refreshHistory () {
        this.$apollo.queries.history.refresh();
      },

      refreshCrossroads () {
        this.$apollo.queries.crossroads.refresh();
      },

      flyToCrossroad (crossroadId) {
        const crossroad = this.crossroads.find((crossroad) => crossroad.id === crossroadId);

        this.$store.commit('map/flyTo', {
          location: crossroad.location,
          zoom: 19,
        });
      },

      showCrossroad (crossroadId) {
        if (this.map.crossroads.find((crossroad) => crossroad.id === crossroadId) === undefined) {
          const crossroad = this.crossroads.find((crossroad) => crossroad.id === crossroadId);
          this.map.crossroads.push(cloneDeep(crossroad));
        }
      },

      hideCrossroad (crossroadId) {
        const index = this.map.crossroads.findIndex((crossroad) => crossroad.id === crossroadId);
        if (index !== -1) {
          this.map.crossroads.splice(index, 1);
        }
      },

      hideAllCrossroads () {
        this.map.crossroads = [];
      },

      showAllCrossroads () {
        this.map.crossroads = cloneDeep(this.crossroads);
      },

      getCityAverageSpeedTo () {
        const now = moment(new Date());
        const seconds = this.timeline.step * 60;
        const secondsToRemove = now.unix() % seconds;
        now.subtract(secondsToRemove, 'seconds');

        return now;
      },
    },
  };
</script>

<style scoped lang="sass">
  .map-tools
    pointer-events: none
    position: absolute
    top: 0
    left: 0

    .col *
      pointer-events: initial

  .map-menu-right
    position: absolute
    top: 0
    right: 0

  .btn-square
    min-width: initial !important
    width: 40px
    height: 60px
    font-size: 20px
</style>
